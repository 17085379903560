import React, { useEffect, useState } from "react";
import SketchFabWrapper from "./components/SketchFabWrapper";
import Resizer from "./components/videoCapture/Resizer";
import VideoCapture from "./components/videoCapture/VideoCapture";
import styled from "styled-components";
import SettingsGui, {
  defaultSettings,
  Settings,
} from "./components/SettingsGUI";

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

function App() {
  const [settings, setSettings] = useState(defaultSettings);
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  function handleSettingChange(newSettings: Settings) {
    setSettings(newSettings);
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <Layer>
        <SketchFabWrapper xEffect={settings.xEffect} yEffect={settings.yEffect} />
      </Layer>
      <Layer style={{ visibility: settings.hideVideo ? "hidden" : "visible" }}>
        <Resizer heightFraction={0.2} actualHeight={480}>
          <VideoCapture />
        </Resizer>
      </Layer>
      <Layer>
        <SettingsGui settingsCallback={handleSettingChange} />
      </Layer>
    </div>
  );
}

export default App;
