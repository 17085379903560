 import {PoseData} from "../../utils/types";

type FingerLookupType = {
  thumb: number[];
  indexFinger: number[];
  middleFinger: number[];
  ringFinger: number[];
  pinky: number[];
};

let fingerLookupIndices: FingerLookupType = {
  thumb: [0, 1, 2, 3, 4],
  indexFinger: [0, 5, 6, 7, 8],
  middleFinger: [0, 9, 10, 11, 12],
  ringFinger: [0, 13, 14, 15, 16],
  pinky: [0, 17, 18, 19, 20],
};

const rightWristIndex = 10;
// const rightShoulderIndex = 6;

function prop<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

class DrawPose {
  private readonly ctx: CanvasRenderingContext2D | null;
  private readonly canvas: HTMLCanvasElement;
  private readonly video: HTMLVideoElement;

  constructor(canvas: HTMLCanvasElement, video:HTMLVideoElement) {
    this.ctx = canvas.getContext("2d");
    this.canvas = canvas;
    this.video = video;
  }

  private drawPoint(x: number, y: number, r: number) {
    if (this.ctx) {
      this.ctx.strokeStyle = "white";
      this.ctx.fillStyle = "white";
      this.ctx.beginPath();
      this.ctx.arc(x, y, r, 0, 2 * Math.PI);
      this.ctx.fill();
    }
  }

  private drawPath(points: number[][], closePath: boolean) {
    const region = new Path2D();
    region.moveTo(points[0][0], points[0][1]);
    for (let i = 1; i < points.length; i++) {
      const point = points[i];
      region.lineTo(point[0], point[1]);
    }

    if (closePath) {
      region.closePath();
    }
    if (this.ctx) {
      this.ctx.stroke(region);
    }
  }

  private reflectPoints(pts: number[][]) {
    const arr = [];
    const w = this.canvas ? this.canvas.width : 0;
    for (let i = 0; i < pts.length; i++) {
      const x = w - pts[i][0];
      const y = pts[i][1];
      arr.push([x, y]);
    }
    return arr;
  }

  public clearCanvas(){
    if (this.ctx && this.canvas) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }

  public drawKeypoints(keypoints: number[][]) {
    const keypointsArray = this.reflectPoints(keypoints);

    for (let i = 0; i < keypointsArray.length; i++) {
      const x = keypointsArray[i][0];
      const y = keypointsArray[i][1];
      this.drawPoint(x - 2, y - 2, 3);
    }

    let finger: keyof typeof fingerLookupIndices;
    for (finger in fingerLookupIndices) {
      const digitArr = prop(fingerLookupIndices, finger);
      const points: number[][] = digitArr.map((idx) => keypoints[idx]);
      const reflectedPoints = this.reflectPoints(points);
      this.drawPath(reflectedPoints, false);
    }
  }

  public drawBodyPose(
      canvas: HTMLCanvasElement,
      pose: PoseData
  ) {
    const ctx = canvas.getContext("2d");
    if (ctx !== null) {
      ctx.strokeStyle = "black";
      const scale = canvas.width / this.video.width;
      pose.keypoints.forEach((pt, idx) => {
        if (idx === rightWristIndex) {
          ctx.fillStyle = "orangered";
        } else {
          ctx.fillStyle = "white";
        }
        if (pt.score > 0.5) {
          ctx.beginPath();
          const x = canvas.width - pt.position.x * scale;
          const y = pt.position.y * scale;
          ctx.arc(x, y, 5, 0, Math.PI * 2);
          ctx.fill();
          ctx.stroke();
        }
      });
    }
  }
}

export default DrawPose;
