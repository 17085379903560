// declare var posenet: any;
//import "@tensorflow/tfjs-backend-wasm";
import "@tensorflow/tfjs-backend-cpu";
import "@tensorflow/tfjs-backend-webgl";
import * as posenet from "@tensorflow-models/posenet";

export async function createPosenet(width, height) {
  // Quicker less accurate
  const params = {
    architecture: "MobileNetV1",
    outputStride: 16,
    //inputResolution: { width, height },
    multiplier: 0.75, // only used by mobile model 1.0, 0.75 or 0.5
  };
  /*
  const params = {
    architecture: "ResNet50",
    outputStride: 32,
    quantBytes: 1,
  };
*/
  if (width !== undefined && height !== undefined) {
    params.inputResolution = { width, height };
  }

  const net = await posenet.load();
  return net;
}

export async function poseFunc(net, imageElement) {
  const imageScaleFactor = 0.5;
  const flipHorizontal = true;
  const outputStride = 16;

  const pose = await net.estimateSinglePose(
    imageElement,
    imageScaleFactor,
    flipHorizontal,
    outputStride
  );
  return pose;
}
