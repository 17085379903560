import React, { useEffect, useRef } from "react";

function SketchFab({ width, height, getApi, modelUID }) {
  const runOnceRef = useRef(0);

  useEffect(() => {
    if (runOnceRef.current === 0) {
      runOnceRef.current = 1;
      const iframe = document.getElementById("api-frame");
      // URL for sketch fab file
      // https://sketchfab.com/3d-models/library-480b29f5a9654e0bbff511b1494b03fc
      // old url
      //const uid = "480b29f5a9654e0bbff511b1494b03fc";

      // original characters
      //const uid = "a871027ca28347ed9aa4ce1bf823d7c6";

      // eslint-disable-next-line no-undef
      const client = new Sketchfab(iframe);

      client.init(modelUID, {
        success: function onSuccess(api) {
          api.start();
          api.addEventListener("viewerready", function () {
            // API is ready to use
            api.getCameraLookAt(function (err, camera) {
              console.log("POSITION >> ", camera.position); // [x, y, z]
              console.log("TARGET >> ", camera.target); // [x, y, z]
            });
            if (typeof getApi === "function" && api !== null) {
              getApi(api);
            }
            console.log("Viewer is ready");
          });
        },
        error: function onError() {
          console.log("Viewer error");
        },

        // Override default to stop this happening
        autoSpin: 0,
        camera: 0,
        scrollwheel: 0,
        ui_controls: 0,
        ui_general_controls: 0,
        ui_fullscreen: 0, //hide full screen button
        ui_help: 0,
        ui_settings: 0,
        ui_stop: 0,
        double_click: 0,
        ui_annotations: 0,
        ui_hint: 0,
        ui_infos: 0,
        ui_inspector: 0,
        ui_ar_help: 0,
      });
    }
  }, [getApi, modelUID]);

  return (
    <iframe
      src=""
      id="api-frame"
      allow="autoplay; fullscreen; vr"
      allowvr="true"
      allowFullScreen
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
      title={"Moomoo"}
      style={{ width: width, height: height }}
    />
  );
}

SketchFab.defaultProps = {
  width: 640,
  height: 480,
  getApi: null,
};

export default SketchFab;
