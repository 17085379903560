import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { createPosenet, poseFunc } from "./Posenet";
import { PoseData } from "../../utils/types";

const defaultPeriod = 20;

class BodyGestures {
  private net: any | null = null;
  private readonly period: number;
  private readonly video: HTMLVideoElement;
  private digitStream: Subject<PoseData> = new Subject();
  private digitStreamOut = this.digitStream.pipe(map((v) => v));

  constructor(video: HTMLVideoElement, period: number = defaultPeriod) {
    this.period = period;
    this.video = video;
    this.init();
  }

  async init(): Promise<void> {
    if (this.video !== null) {
      this.net = await createPosenet(
        this.video.videoWidth,
        this.video.videoHeight
      );
      setInterval(() => {
        this.detect();
      }, this.period);
    }
  }

  async detect(): Promise<void> {
    if (
      typeof this.video !== "undefined" &&
      this.video !== null &&
      this.video.readyState === 4
    ) {
      if (this.net !== null) {
        const pose = await poseFunc(this.net, this.video);
        this.digitStream.next(pose);
      }
    }
  }

  get observable(): Observable<PoseData> {
    return this.digitStreamOut;
  }
}

export default BodyGestures;
