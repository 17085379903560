import Webcam from "react-webcam";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import AppStore from "../../store/store";
import {
  clearCanvas,
  drawHandData,
  drawSmoothed,
} from "../PosenetVideo/PosenetUtils";
import DrawPose from "../FingerTracking/DrawPose";
import { HandposeData } from "../../utils/types";
import { SmoothedPoints } from "../PosenetVideo/gesture";
import { estimateHandGesture } from "../FingerTracking/FingerPose";

const Layer = styled.div`
  position: absolute;
`;

const LayerContainer = styled.div`
  position: relative;
`;

const VideoCapture = () => {
  const webcamRef = useRef(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const drawRef = useRef<DrawPose>(null);
  const lastPoseDataRef = useRef<SmoothedPoints | null>(null);
  const lastHandDataRef = useRef<number[][] | null>(null);
  const lastHandGesture = useRef<any>(null);

  const [canvasDimensions, setCanvasDimensions] = useState<{
    w: number;
    h: number;
  }>({ w: 640, h: 480 });

  function handleVideo() {
    const videos: HTMLCollection = document.getElementsByTagName("video");
    const video: HTMLVideoElement = videos[0] as HTMLVideoElement;
    video.addEventListener("loadeddata", () => {
      console.log("Video dataloaded");
      handleVideoLoaded(video);
    });
  }

  function handleVideoLoaded(video: HTMLVideoElement) {
    console.log("VIDEO IS READY");
    // Match Canvas to video size
    setCanvasDimensions({
      w: video.videoWidth,
      h: video.videoHeight,
    });
    const store = AppStore.getInstance();
    store.dispatch({ type: "VIDEO_READY", payload: { video } });
    if (canvasRef.current) {
      // @ts-ignore
      drawRef.current = new DrawPose(canvasRef.current, video);
    }
  }

  // TODO dispatch video not ready when component is destroyed

  useEffect(() => {
    const store = AppStore.getInstance();
    const events = store.observable;
    let animatingFlag = true;
    // subscribe to data updates

    const subscription = events.subscribe((data) => {
      switch (data.type) {
        case "smooth":
          // @ts-ignore
          lastPoseDataRef.current = data.payload;
          break;
        case "handpose":
          const handData: HandposeData = data.payload as HandposeData;
          if (handData.length > 0) {
            const landmarks: any = handData[0].landmarks;
            lastHandDataRef.current = landmarks as number[][];
            lastHandGesture.current = estimateHandGesture(landmarks);
            // console.log(lastHandGesture.current);
          } else {
            lastHandDataRef.current = null;
            lastHandGesture.current = null;
          }
          break;
      }
    });

    function animationLoop() {
      const canvas = canvasRef.current;
      if (animatingFlag && canvas) {
        clearCanvas(canvas);
        if (lastHandDataRef.current) {
          drawHandData(canvas, lastHandDataRef.current);
        }
        if (lastPoseDataRef.current) {
          drawSmoothed(canvas, lastPoseDataRef.current);
        }
        requestAnimationFrame(animationLoop);
      }
    }

    animationLoop();

    return () => {
      animatingFlag = false;
      subscription.unsubscribe();
    };
  }, []);

  return (
    <LayerContainer>
      <Layer>
        <Webcam
          ref={webcamRef}
          onUserMedia={handleVideo}
          audio={false}
          width={640}
          height={480}
          mirrored={true}
        />
      </Layer>
      <Layer>
        <canvas
          ref={canvasRef}
          width={canvasDimensions.w}
          height={canvasDimensions.h}
          style={{
            width: canvasDimensions.w,
            height: canvasDimensions.h,
            backgroundColor: "rgba(0,0,255,0.3)",
          }}
        />
      </Layer>
    </LayerContainer>
  );
};

export default VideoCapture;
