import { Keypoint } from "@tensorflow-models/posenet";
import { find, get } from "lodash";
import {Observable, Subject} from "rxjs";

export type PointOrNull = { x: number; y: number } | null;

export type SmoothPoint = {
  part: string;
  position: PointOrNull;
  velocity: PointOrNull;
};

export type VelocityTracker = {
  max: number;
  min: number;
  nullCounts: number;
};

export type SmoothedPoints = SmoothPoint[];

export type SustainedVelocity = {
  t: number;
  vel: PointOrNull;
  pos: PointOrNull;
};

export type SwipeDirection = "NONE" | "LEFT" | "RIGHT";

export type SwipeEvent = {
  type : SwipeDirection
}

export class SmoothKeyPoint {
  private _buffer: { pt: Keypoint; t: number }[] = [];
  public minimumScore: number = 0.5;
  public killWindow: number = 600;
  public averageWindow: number = 50;

  addPoint(pt: Keypoint, now: number) {
    const t = now;
    if (pt.score > this.minimumScore) this._buffer.push({ pt, t });
  }

  scrubBuffer(now: number) {
    this._buffer = this._buffer.filter((b) => now - b.t < this.killWindow);
  }

  average(now: number): { x: number; y: number } | null {
    this.scrubBuffer(now);
    const minTime = now - this.averageWindow;
    const averageList = this._buffer.filter((b) => b.t >= minTime);
    const l = averageList.length;
    if (l < 1) return null;
    const position = { x: 0, y: 0 };
    averageList.forEach((b) => {
      position.x += b.pt.position.x;
      position.y += b.pt.position.y;
    });
    position.x /= l;
    position.y /= l;
    return position;
  }

  //velocity(now: number): { x: number; y: number } | null {
  // this.scrubBuffer(now);
  velocity(): { x: number; y: number } | null {
    const dataSource = this._buffer;
    const l = dataSource.length;
    if (l < 2) return null;
    const idx = l - 1;
    const dx = dataSource[0].pt.position.x - dataSource[idx].pt.position.x;
    const dy = dataSource[0].pt.position.y - dataSource[idx].pt.position.y;
    const dt = dataSource[0].t - dataSource[idx].t;
    return { x: dx / dt, y: dy / dt };
  }
}

export class SmoothKeyPoints {
  private _buffer: { part: string; pts: SmoothKeyPoint }[] = [];
  private _sustainedHorzVelocity: SustainedVelocity[] = [];
  private _swipeDirection: SwipeDirection = "NONE";
  // private _swipeChangeTime: number = new Date().getTime();
  private _swipeStream : Subject<SwipeEvent> = new Subject<SwipeEvent>()

  public addPoints(pts: Keypoint[]) {
    const now = new Date().getTime();
    pts.forEach((pt) => {
      let buff: { part: string; pts: SmoothKeyPoint } | undefined = find(
        this._buffer,
        (item) => item.part === pt.part
      );
      if (buff === undefined) {
        buff = { part: pt.part, pts: new SmoothKeyPoint() };
        this._buffer.push(buff);
      }
      buff.pts.addPoint(pt, now);
    });
  }

  private trackVelocities(
    calculation: SmoothedPoints,
    now: number,
    threshold: number = 0.10,
    period: number = 400,
    maxNulls: number = 0.5
  ) {
    const rightWristData = find(calculation, (c) => c.part === "rightWrist");
    const rightElbowData = find(calculation, (c) => c.part === "rightElbow");

    const elbowY: number | null = get(rightElbowData, "position.y", null);
    const wristY: number | null = get(rightWristData, "position.y", null);

    if (elbowY && wristY && wristY < elbowY) {
      this._sustainedHorzVelocity.push({
        t: now,
        vel: rightWristData ? rightWristData.velocity : null,
        pos: rightWristData ? rightWristData.position : null,
      });
    } else {
      this._sustainedHorzVelocity.push({
        t: now,
        vel: null,
        pos: null,
      });
    }

    this._sustainedHorzVelocity = this._sustainedHorzVelocity.filter(
      (p) => p.t >= now - period
    );
    const initialValue: VelocityTracker = {
      min: Number.MAX_SAFE_INTEGER,
      max: Number.MIN_SAFE_INTEGER,
      nullCounts: 0,
    };
    const result = this._sustainedHorzVelocity.reduce(
      (acc: VelocityTracker, curr) => {
        if (curr.vel === null) {
          acc.nullCounts += 1;
        } else {
          acc.min = Math.min(acc.min, curr.vel.x);
          acc.max = Math.max(acc.max, curr.vel.x);
        }
        return acc;
      },
      initialValue
    );
    const l = this._sustainedHorzVelocity.length;
    const normalisedNulls = result.nullCounts / l

    const oldSwipeDirection = this._swipeDirection;

    if (normalisedNulls < maxNulls) {
      if (result.min > threshold) {
        this._swipeDirection = "LEFT";
      } else if (result.max < -threshold) {
        this._swipeDirection = "RIGHT";
      } else {
        this._swipeDirection = "NONE";
      }
    }

    if (this._swipeDirection !== oldSwipeDirection) {
      // console.log("SWIPE", this._swipeDirection);
      this._swipeStream.next({type:this._swipeDirection})
    }

    return { max: result.max, min: result.min, nulls: normalisedNulls };
  }

  public average(): {
    calculation: SmoothedPoints;
    tracking: { max: number; min: number; nulls: number };
  } {
    const now = new Date().getTime();
    const calculation: SmoothedPoints = this._buffer.map((b) => {
      const position = b.pts.average(now);
      const velocity = b.pts.velocity();
      return {
        position,
        velocity,
        part: b.part,
      };
    });
    const tracking = this.trackVelocities(calculation, now);
    return { calculation: calculation, tracking };
  }

  public get observable ():Observable<SwipeEvent>{
    return this._swipeStream as Observable<SwipeEvent>;
  }
}
