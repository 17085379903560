import SketchFab from "./SketchFab";
import { FC, useEffect, useRef } from "react";
import AppStore from "../store/store";

// Constants
const modelUID = "2895a57f9ad348d0bae5c836165ab5ae";
const initialCamPos = [
  362.28015847629547, 46.29135863322068, 104.2119516947395,
];
const initialTargetPos = [
  -120.56344918636003, 26.29517824772835, 122.8568537828563,
];
const videoWidth = 640;
const videoHeight = 480;
// const xEffect = -120;
// const yEffect = -90;

type SketchFabWrapperProps = {
  xEffect: number;
  yEffect: number;
};

const SketchFabWrapper: FC<SketchFabWrapperProps> = ({ xEffect, yEffect }) => {
  const apiRef = useRef<any>(null);
  const camPosRef = useRef<number[]>(initialCamPos);
  const targetPosRef = useRef<number[]>(initialTargetPos);
  const isAnimatingRef = useRef<boolean>(true);
  const xEffectRef = useRef<number>(xEffect);
  const yEffectRef = useRef<number>(yEffect);

  function handleApi(api: any) {
    apiRef.current = api;
  }

  useEffect(()=>{
    xEffectRef.current = xEffect
    yEffectRef.current = yEffect
  },[xEffect,yEffect])

  useEffect(() => {
    let once = 1;
    const store = AppStore.getInstance();

    const subscription = store.observable.subscribe((event) => {
      if (event.type === "nose") {
        if (once) {
          console.log(event);
          once -= 1;
        }
        if (event.payload && event.payload.position) {
          const noseX = event.payload.position.x;
          const noseY = event.payload.position.y;
          const noseXFraction = (noseX - videoWidth / 2) / videoWidth;
          const noseYFraction = (noseY - videoHeight / 2) / videoHeight;
          const newCamPosY = initialCamPos[1] - noseXFraction * xEffectRef.current;
          const newCamPosZ = initialCamPos[2] - noseYFraction * yEffectRef.current;
          camPosRef.current = [
            camPosRef.current[0],

            newCamPosY,
            newCamPosZ,
            // camPosRef.current[2],
          ];
        }
      }
    });

    function animationLoop() {
      if (isAnimatingRef.current) {
        if (apiRef.current) {
          const duration = 0;
          const callback = null;
          apiRef.current.setCameraLookAt(
            camPosRef.current,
            targetPosRef.current,
            duration,
            callback
          );
        }
        window.requestAnimationFrame(animationLoop);
      }
    }

    animationLoop();

    // Remove subscription
    // Stop animating
    return () => {
      subscription.unsubscribe();
      isAnimatingRef.current = false;
    };
  }, []);

  return (
    <SketchFab
      height={"100vh"}
      width={"100vw"}
      getApi={handleApi}
      modelUID={modelUID}
    />
  );
};

export default SketchFabWrapper;
