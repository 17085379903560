import { BehaviorSubject, Observable, Subject } from "rxjs";
import BodyGestures from "../components/FingerTracking/BodyGestures";
import { Coord, PoseData } from "../utils/types";
import "@tensorflow/tfjs-backend-cpu";
import "@tensorflow/tfjs-backend-webgl";
import "@tensorflow/tfjs-backend-wasm";
import { ready } from "@tensorflow/tfjs";

import {
  SmoothedPoints,
  SmoothKeyPoints,
  SmoothPoint,
} from "../components/PosenetVideo/gesture";
import { distinctUntilChanged, filter } from "rxjs/operators";
// import HandGestures from "../components/FingerTracking/handpose";
// import { estimateHandGesture } from "../components/FingerTracking/FingerPose";

type AppState = {
  video: {
    element: HTMLVideoElement | null;
    ready: boolean;
    height: number;
    width: number;
  };
  posenet: {
    observable: Observable<PoseData> | null;
  };
  fingerpose: {
    observable: Observable<any> | null;
  };
  guiArea: {
    width: number;
    height: number;
    gestureZoom: number;
    showSetting: boolean;
  };
};

type VideoReadyAction = {
  type: "VIDEO_READY";
  payload: { video: HTMLVideoElement | null };
};

type Action =
  | VideoReadyAction
  | { type: "SET_GESTURE_ZOOM"; payload: number }
  | { type: "SET_BACKGROUND_COLOUR"; payload: string }
  | { type: "CLEAR_SELECTED_BOXES" };

export type EventStreamType = any; //TODO give this a type

export type BoxEvent = {
  type: string;
  payload: any;
};

export type HandGesture = any; // TODO give this a type

type SensingEvents =
  | {
      type: "posenet";
      payload: PoseData;
    }
  | { type: "smooth"; payload: SmoothedPoints }
  | {
      type: "rightWrist";
      payload: SmoothPoint | null;
    }
  | { type: "handpose"; payload: any }
  | {
      type: "nose";
      payload: SmoothPoint | null;
    };

const defaultBackgroundColor = "#DCDFE2";

function compareGestureArray(a: any, b: any): boolean {
  if (a === null || b === null) {
    if (a === null && b === null) return true;
    return false;
  }
  if (a.gestures.length !== b.gestures.length) return false;
  let same = true;
  for (let i = 0; i < a.gestures.length; i++) {
    same = same && a.gestures[i].name === b.gestures[i].name;
  }
  return same;
}

class AppStore {
  private static _instance: AppStore | null = null;
  private _sensingStream: Subject<SensingEvents> = new Subject<SensingEvents>();
  private _backgroundColourStream: BehaviorSubject<string> =
    new BehaviorSubject(defaultBackgroundColor);
  private _backgroundUpdateStream: Observable<string> =
    this._backgroundColourStream.pipe(distinctUntilChanged());
  private smooth: SmoothKeyPoints = new SmoothKeyPoints();
  private _boxEventStream = new Subject<BoxEvent>();
  private _fingerStream = new Subject<HandGesture>();
  private _gestureStream = this._fingerStream.pipe(
    filter((value) => value !== null),
    filter((value) => value.gestures.length !== 0),
    distinctUntilChanged(compareGestureArray)
  );
  public swipeStream = this.smooth.observable;

  private _state: AppState = {
    video: {
      element: null,
      ready: false,
      width: 1,
      height: 1,
    },
    posenet: {
      observable: null,
    },
    fingerpose: {
      observable: null,
    },
    guiArea: {
      width: 800,
      height: 600,
      gestureZoom: 1,
      showSetting: true,
    },
  };

  public static getInstance() {
    if (!AppStore._instance) {
      AppStore._instance = new AppStore();
    }
    return AppStore._instance;
  }

  public vidToGui(pt: Coord): Coord {
    const w2 = this._state.guiArea.width / 2;
    const h2 = this._state.guiArea.height / 2;
    // Translate coords from video to svg
    const x = w2 - (this._state.guiArea.width * pt.x) / this._state.video.width;
    const y =
      (this._state.guiArea.height * pt.y) / this._state.video.height - h2;
    // Multiply by zoom factor
    let xx = Math.max(Math.min(w2, this._state.guiArea.gestureZoom * x), -w2);
    let yy = Math.max(Math.min(h2, this._state.guiArea.gestureZoom * y), -h2);
    return { x: xx, y: yy };
  }

  private async handleVideoReady(action: VideoReadyAction) {
    if (action.payload.video === null) return;
    const video = action.payload.video;
    this._state.video = {
      ready: true,
      element: video,
      height: video.videoHeight,
      width: video.videoWidth,
    };

    // Wait for tensorflow to be ready
    await ready();
    console.log("tensorflow is ready");

    const bodyTracker = new BodyGestures(video, 20);
    this._state.posenet.observable = bodyTracker.observable;
    bodyTracker.observable.subscribe((poseData) => {
      this._sensingStream.next({
        type: "posenet",
        payload: poseData,
      });
      this.smooth.addPoints(poseData.keypoints);
      const average = this.smooth.average();
      this._sensingStream.next({
        type: "smooth",
        payload: average.calculation,
      });

      const keypoint: SmoothPoint | undefined = average.calculation.find(
        (d) => d.part === "rightWrist"
      );
      this._sensingStream.next({
        type: "rightWrist",
        payload: keypoint ? keypoint : null,
      });

      const noseKeyPoint: SmoothPoint | undefined = average.calculation.find(
        (d) => d.part === "nose"
      );
      // console.log("About to send nose position");
      this._sensingStream.next({
        type: "nose",
        payload: noseKeyPoint ? noseKeyPoint : null,
      });
    });
    // TODO Unsubscribe when this object is destroyed

    // Handpose is not required at the moment
    /*
    const handpose = new HandGestures(video);
    handpose.observable.subscribe((handData) => {
      this._sensingStream.next({
        type: "handpose",
        payload: handData,
      });
      let estimate: any = null;
      if (handData.length > 0) {
        const landmarks: any = handData[0].landmarks;
        estimate = estimateHandGesture(landmarks);
      }
      this._fingerStream.next(estimate);
    });
    // TODO Unsubscribe when this object is destroyed
     */
    this._gestureStream.subscribe((next) => {
      if (next.gestures.find((g: any) => g.name === "closed hand"))
        this._boxEventStream.next({ type: "GESTURE", payload: "closed hand" });
    });
  }

  dispatch(action: Action) {
    switch (action.type) {
      case "VIDEO_READY":
        this.handleVideoReady(action);
        break;
      case "SET_GESTURE_ZOOM":
        this._state.guiArea.gestureZoom = action.payload;
        break;
      case "SET_BACKGROUND_COLOUR":
        this._backgroundColourStream.next(action.payload);
        break;
      case "CLEAR_SELECTED_BOXES":
        this._boxEventStream.next({ type: "CLEAR_SELECTION", payload: null });
        break;
      // do nothing
    }
  }

  get observable(): Observable<EventStreamType> {
    return this._sensingStream as Observable<EventStreamType>;
  }

  get guiHeight(): number {
    return this._state.guiArea.height;
  }

  get guiWidth(): number {
    return this._state.guiArea.width;
  }

  get backgroundColourObservable(): Observable<string> {
    return this._backgroundUpdateStream;
  }

  get boxEventStream(): Observable<BoxEvent> {
    return this._boxEventStream as Observable<BoxEvent>;
  }
}

export default AppStore;
