import { FC, useEffect, useState } from "react";

type ResizerProps = {
  heightFraction: number;
  actualHeight: number;
};

const Resizer: FC<ResizerProps> = ({
  heightFraction,
  actualHeight,
  children,
}) => {
  const [scale, setScale] = useState<number>(0.5);

  useEffect(() => {
    function calculateScale() {
      const vh = window.innerHeight;
      const th = vh * heightFraction;
      setScale(th / actualHeight);
    }

    calculateScale();
    window.addEventListener("resize", calculateScale);
    return () => {
      window.removeEventListener("resize", calculateScale);
    };
  }, [actualHeight, heightFraction]);

  return (
    <div
      style={{ transform: `scale(${scale})`, position: "absolute", left: 0 }}
    >
      {children}
    </div>
  );
};
export default Resizer;
