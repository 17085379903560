import React, { FC, useState } from "react";
import "react-dat-gui/dist/index.css";
import DatGui, { DatBoolean, DatFolder, DatNumber } from "react-dat-gui";

export type Settings = {
  xEffect: number;
  yEffect: number;
  hideVideo: boolean;
};

type SettingsProps = {
  settingsCallback?: (settings: Settings) => void;
};

export const defaultSettings = {
  xEffect: 120,
  yEffect: 90,
  hideVideo: true,
};

const SettingsGui: FC<SettingsProps> = ({ settingsCallback }) => {
  const [settings, setSettings] = useState<Settings>(defaultSettings);

  function handleUpdate(updates: any) {
    const newSettings: Settings = { ...settings, ...updates };
    setSettings(newSettings);
    if (settingsCallback) {
      settingsCallback(newSettings);
    }
  }

  return (
    <DatGui data={settings} onUpdate={handleUpdate}>
      <DatFolder title={"settings"} closed={true}>
        <DatNumber
          path="xEffect"
          label="x effect"
          min={-200}
          max={200}
          step={10}
        />
        <DatNumber
          path="yEffect"
          label="y effect"
          min={-150}
          max={150}
          step={10}
        />
        <DatBoolean path={"hideVideo"} label="hide video" />
      </DatFolder>
    </DatGui>
  );
};

export default SettingsGui;
