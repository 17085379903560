import { Keypoint, Pose } from "@tensorflow-models/posenet";
import { SmoothedPoints } from "./gesture";

export function drawPosenetData(canvas: HTMLCanvasElement, data: Pose) {
  const w = canvas.width;
  const h = canvas.height;
  const threshold = 0.5;
  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.fillStyle = "rgba(0, 0, 0, 0)";
    ctx.clearRect(0, 0, w, h);
    ctx.lineWidth = 0;
    ctx.fillStyle = "white";
    data.keypoints.forEach((pt: Keypoint) => {
      if (pt.score > threshold) {
        ctx.beginPath();
        ctx.arc(pt.position.x, pt.position.y, 4, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
      }
    });
  }
}

export function clearCanvas(canvas: HTMLCanvasElement){
  const w = canvas.width;
  const h = canvas.height;
  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.fillStyle = "rgba(0, 0, 0, 0)";
    ctx.clearRect(0, 0, w, h);
  }
}

export function drawHandData(canvas: HTMLCanvasElement, data: number[][]) {
  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.lineWidth = 0;
    ctx.fillStyle = "aqua";
    data.forEach((pt: number[]) => {
      ctx.beginPath();
      ctx.arc(pt[0], pt[1], 4, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.fill();
    });
  }
}

export function drawSmoothed(
  canvas: HTMLCanvasElement | null | undefined,
  data: SmoothedPoints,
  mirror: boolean = true
) {
  if (!canvas) return;

  const w = canvas.width;
  // const h = canvas.height;
  const ctx = canvas.getContext("2d");

  if (ctx) {
    //ctx.fillStyle = "rgba(0, 0, 0, 0)";
    //ctx.clearRect(0, 0, w, h);
    ctx.lineWidth = 0;
    ctx.fillStyle = "white";
    data.forEach(
      (pt: {
        velocity: { x: number; y: number } | null;
        position: { x: number; y: number } | null;
        part: string;
      }) => {
        if (pt.position && pt.velocity) {
          ctx.beginPath();
          let x;
          let vx;
          if (mirror) {
            x = w - pt.position.x;
            vx = -pt.velocity.x;
          } else {
            x = pt.position.x;
            vx = pt.velocity.x;
          }

          const k = 200;
          let color = "blue";
          if (Math.abs(vx) > 0.1) {
            color = "orangered";
          }

          ctx.arc(x, pt.position.y, 4, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.fill();
          ctx.beginPath();
          ctx.strokeStyle = color;
          ctx.moveTo(x, pt.position.y);
          ctx.lineTo(x + k * vx, pt.position.y + k * pt.velocity.y);
          ctx.stroke();
          ctx.closePath();
        }
      }
    );
  }
}
